<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  isEdit = false;
                  modal_record_details = true;
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <template #cell(queues)="data">
          {{ getQueueDisplayNames(data.value) }}
        </template>

        <!-- Column: Actions -->

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group :label="lang('t_displayName')">
            <b-form-input
              id="displayName"
              v-model="currentData.display_name"
              trim
            />
          </b-form-group>
          <b-form-group :label="lang('t_queue')">
            <v-select
              v-model="currentData.queues"
              :options="queues"
              :placeholder="lang('t_queue')"
              :reduce="(option) => option.internal_name"
              label="display_name"
              multiple
            >
            </v-select>
          </b-form-group>
          <b-form-group :label="lang('t_action')">
            <v-select
              v-model="currentData.event"
              :options="events"
              :placeholder="lang('t_action')"
              :reduce="(option) => option"
              label="event"
            >
            </v-select>
          </b-form-group>
          <b-form-group :label="lang('t_type')">
            <v-select
              v-model="currentData.method"
              :options="['GET', 'POST', 'PUT', 'DELETE']"
              :placeholder="lang('t_type')"
            >
            </v-select>
          </b-form-group>
          <b-form-group>
            <b-badge
              v-if="
                ['queue', 'after_call'].includes(currentData.event.category)
              "
              class="mr-25 cursor-pointer"
              variant="primary"
              @click="doCopy('RemoteNumber')"
            >
              {{ "RemoteNumber" }}
            </b-badge>
            <b-badge
              v-if="
                ['queue', 'after_call'].includes(currentData.event.category)
              "
              class="mx-25 cursor-pointer"
              variant="primary"
              @click="doCopy('InteractionID')"
            >
              {{ "InteractionID" }}
            </b-badge>
            <b-badge
              class="mx-25 cursor-pointer"
              variant="primary"
              @click="doCopy('Agent')"
            >
              {{ "Agent" }}
            </b-badge>
            <b-badge
              v-if="
                ['queue', 'after_call'].includes(currentData.event.category)
              "
              class="mx-25 cursor-pointer"
              variant="primary"
              @click="doCopy('Queue')"
            >
              {{ "Queue" }}
            </b-badge>
          </b-form-group>
          <b-form-group :label="lang('t_url')">
            <b-form-input
              v-model="currentData.url"
              trim
              :placeholder="lang('t_url')"
            />
          </b-form-group>
          <b-form-group :label="lang('t_headers')">
            <b-form-textarea
              rows="3"
              v-model="currentData.headers"
              trim
              :placeholder="lang('t_headers')"
            />
          </b-form-group>

          <b-form-group :label="lang('t_body')">
            <b-form-textarea
              rows="3"
              v-model="currentData.body"
              trim
              :placeholder="lang('t_body')"
            />
          </b-form-group>
          <b-form-checkbox
            v-if="currentData.event.category != 'after_call'"
            v-model="currentData.open_on_new_window"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
                {{ lang("t_openOnNewWindow") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormTextarea,
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      currentData: {
        display_name: "",
        queues: [],
        event: { category: 'after_call', event: "FinishInteraction" },
        method: "GET",
        url: "",
        headers: `{\n"X-Client":"Voyce"\n}`,
        body: `{\n"RemoteNumber":"{{RemoteNumber}}"\n}`,
        open_on_new_window: false
      },
      events: [
        { category: 'after_call', event: "FinishInteraction" },
        { category: 'queue', event: "InteractionSuccessful" },
        { category: 'queue', event: "InteractionJoinedQueue" },
        { category: 'queue', event: "InteractionRemovedFromQueue" },
        { category: 'queue', event: "InteractionAbandoned" },
        { category: 'queue', event: "AgentAcceptedInteraction" },
        { category: 'queue', event: "AgentRejectedInteraction" },
        { category: 'queue', event: "InteractionPulledBack" },
        { category: 'queue', event: "AgentNoAnswerInteraction" },
        { category: 'queue', event: "InteractionSentToAgent" },
        { category: 'queue', event: "AgentStartedOutbound" },
        { category: 'queue', event: "TransferSentToAgent" },
        { category: 'queue', event: "AgentAcceptedInteractionTransfer" },
        { category: 'queue', event: "AgentRejectedInteractionTransfer" },
        { category: 'agent', event: "AgentLoggedIn" },
        { category: 'agent', event: "AgentLoggedOut" },
        { category: 'agent', event: "AgentJoinedQueue" },
        { category: 'agent', event: "AgentLeftQueue" },
        { category: 'agent', event: "AgentPaused" },
        { category: 'agent', event: "AgentUnpaused" },
        { category: 'agent', event: "AgentAcceptedInteraction" },
        { category: 'agent', event: "AgentRejectedInteraction" },
        { category: 'agent', event: "AgentFinishedInteraction" },
        { category: 'agent', event: "AgentFinalizedInteraction" },
        { category: 'agent', event: "AgentRinging" },
        { category: 'agent', event: "AgentLostCall" },
        { category: 'agent', event: "AgentTransferRinging" },
        { category: 'agent', event: "AgentTransferRejected" },
        { category: 'agent', event: "AgentTransferredCall" },
        { category: 'agent', event: "AcceptedTransferInteraction" },
        { category: 'agent', event: "RejectedTransferInteraction" },
        { category: 'agent', event: "AgentOutboundRemotePickedUp" },


      ],
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      records: [],
      queues: [],
      users: [],
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "method",
          label: globalThis._lang("t_action"),
          sortable: true,
        },
        {
          key: "url",
          label: globalThis._lang("t_url"),
          sortable: true,
        },
        {
          key: "queues",
          label: globalThis._lang("t_queues"),
          sortable: true,
        },

        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
    };
  },
  mounted: async function () {
    await this.get_records();
    await this.GetQueues();
  },
  methods: {
    getQueueDisplayNames(_q) {
      let _queues = _q;
      let text = "";
      for (const item of _queues) {
        if (item != "") {
          text +=
            (this.queues.find((e) => e.internal_name == item) != undefined
              ? this.queues.find((e) => e.internal_name == item).display_name
              : item) + ",";
        }
      }
      text = text.substr(0, text.length - 1);
      return text;
    },

    async doCopy(text) {
      let _text = `{{${text}}}`
      await navigator.clipboard.writeText(_text);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Metin kopyalandı.',
          icon: 'BellIcon',
        },
      })
    },


    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      let p_queues = globalThis.permissions["queue"];
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/webhook-handler/v1/Webhook`)).data;
      console.log("records", response);
      this.records = response.filter(e => !e.app_name);
      this.is_progress = false;
    },
    check_data() {
      if (
        this.currentData.display_name == "" ||
        this.currentData.method == null ||
        this.currentData.event == null ||
        this.currentData.queues.length == 0 ||
        this.currentData.url == ""
      ) {
        return true;
      }
      return false;
    },

    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/webhook-handler/v1/Webhook`, {
            data: this.currentData,
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/webhook-handler/v1/Webhook`, {
            data: this.currentData,
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `/webhook-handler/v1/Webhook/${this.currentData._id}`
        )
      ).data;

      this.is_progress = false;
      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        queues: [],
        display_name: "",
        event: { category: 'after_call', event: "FinishInteraction" },
        method: "GET",
        url: "",
        headers: `{\n"X-Client":"Voyce"\n}`,
        body: `{\n"RemoteNumber":"{{RemoteNumber}}"\n}`,
        open_on_new_window: false
      };
    },

    updateSelected(item) {
      // this.currentData = item;
      this.currentData = {
        ...item,
        profiles: item.profiles,
        temp_profiles: item.profiles,
        break_time: parseInt(item.break_time) / 60,
      };
      console.log("item", item);
      this.isEdit = true;
      this.modal_record_details = true;
      //this.isAddNewUserSidebarActive = true;
    },
    confirmText(item) {
      this.currentData = {
        ...item,
        profiles: item,
        temp_profiles: item,
        break_time: parseInt(item.break_time) / 60,
      };
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];
    const profileOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
